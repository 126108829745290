<template>
  <div class="classify">
    <el-image class="image-logo" :src="require(`../../../assets/image/logoAI.png`)"></el-image>
    <div class="classify-body">
      <div v-for="classify in classifyData" :key="classify.student_questionnaire_classify_id">
        <el-button class="custom-button" :style="{ backgroundColor: randomColor() }" @click="gotoHomework(classify.student_questionnaire_classify_id)">{{ classify.classify_name }}</el-button>
      </div>
      <el-image class="logo-image" :src="require(`../../../assets/image/logomini.png`)"></el-image>
    </div>
  </div>
</template>

<script>
import { getClassify } from '../../../request/api'

export default {
  data() {
    return {
      classifyData: []
    }
  },
  methods: {
    async init () {
      const ret = await getClassify()
      if (ret.status !== 200 || ret.data.code !== '1000') {
        this.$message.error(ret.data.msg)
        return
      }
      this.classifyData = ret.data.data
    },
    gotoHomework (student_questionnaire_classify_id) {
        this.$router.push(
          {
            path: '/exam/questionnaire',
            query: {
              student_questionnaire_classify_id: student_questionnaire_classify_id
            }
          }
        )
    },
    randomColor() {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.classify {
  height: 100vh;
  background: linear-gradient(to bottom right, #6dbeff, #fff);
  .image-logo {
    width: 10vw;
    max-width: 160px;
    margin: 20px 0 0 30px;
  }
  .classify-body {
    height: calc(100% - 160px - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 38px;
    .custom-button {
      width: 320px;
      height: 100px;
      font-size: 28px;
      border-radius: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      color: #fff;
    }
  }
  .logo-image {
    pointer-events: none;
    position: absolute;
    bottom: 0px;
    width: 35vw;
    max-width: 700px;
    opacity: 0.1;
    right: 0px;
  }
}
</style>